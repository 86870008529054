import React, { useEffect, useState } from "react";
import "./App.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DatePicker } from "antd";
import { Select, Input, Button, Pagination } from "antd";
import { allOrders } from "./Api/Apicalls";
import { ApiEndPoints } from "./Api/Apiendpoints";
import moment from "moment";
import { API_URL } from "./Api/Config";
import { RiResetLeftFill } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import dayjs from "dayjs";



export default function Transaction() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1); // Ant Design's Pagination component is 1-based index
  const [data, setData] = useState([]);
  const [ogData, setOgData] = useState([]);
  const [totals, setTotals] = useState({
    upi: 0,
    card: 0,
    cash: 0,
  });
  const [values, setValues] = useState({
    orderId: "",
    paymentType: "",
  });
  const [filterDate, setFilterDate] = useState("");

  const handleReset=()=>{
    setFilterDate("")
    setValues({
      orderId: "",
      paymentType: "",
    })
   
    getAllorders()
  }

  const handleDateChange = (date, dateString) => {
    setFilterDate(dateString);
  };

  const getAllorders = async () => {
    try {
      const response = await allOrders(ApiEndPoints("getAllorders"));
      const sortedOrders = response.orders.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      setData(sortedOrders);
      setOgData(response.orders);
      filterValues(sortedOrders); 
    } catch (err) {
      console.error(err);
    }
  };

  // const filterValues = () => {
  //   const filtered = ogData.filter((product) => {
  //     return (
  //       (!values.orderId || values.orderId === product.orderId.slice(-5)) &&
  //       (!values.paymentType ||
  //         values.paymentType.toUpperCase() ===
  //           product.paymentMethod.toUpperCase()) &&
  //       (filterDate === "" ||
  //         filterDate === moment(product.deliveryDate).format("YYYY-MM-DD"))
  //     );
  //   });

  //   // Calculate totals for each payment method
  //   const totals = {
  //     upi: 0,
  //     card: 0,
  //     cash: 0,
  //   };

  //   filtered.forEach((product) => {
  //     if (product.paymentMethod.toLowerCase() === "upi") {
  //       totals.upi += product.paymentAmount || 0;
  //     } else if (product.paymentMethod.toLowerCase() === "card") {
  //       totals.card += product.paymentAmount || 0;
  //     } else if (product.paymentMethod.toLowerCase() === "cash") {
  //       totals.cash += product.paymentAmount || 0;
  //     }
  //   });

  //   setTotals(totals);
  //   setData(filtered);
  // };

  const filterValues = (dataToFilter) => {
    let filtered;

    // Check if any filters are applied
    if (values.orderId === "" && values.paymentType === "" && filterDate === "") {
      // No filters selected, use the entire dataset
      filtered = dataToFilter;
    } else {
      
      // Filters are selected, apply them
      filtered = ogData.filter((product) => {
        return (
          (!values.orderId || values.orderId ===product._id.slice(-5)) &&
          (!values.paymentType ||
            values.paymentType.toUpperCase() ===
              product.paymentMethod.toUpperCase()) &&
          (filterDate === "" ||
            filterDate === moment(product.deliveryDate).format("YYYY-MM-DD"))
        );
      });
    }

    // Calculate totals for each payment method
    const totals = {
      upi: 0,
      card: 0,
      cash: 0,
    };

    filtered.forEach((product) => {
      if (product.paymentMethod.toLowerCase() === "upi") {
        totals.upi += product.paymentAmount || 0;
      } else if (product.paymentMethod.toLowerCase() === "card") {
        totals.card += product.paymentAmount || 0;
      } else if (product.paymentMethod.toLowerCase() === "cash") {
        totals.cash += product.paymentAmount || 0;
      }
    });

    setTotals(totals);
    setData(filtered);
  };

  // Fetch orders and apply initial filtering on component mount
  useEffect(() => {
    getAllorders();
  }, []);

  // Handle page change
  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <div className="mx-3 px-4">
      <div style={{ padding: "20px 30px", color: "#0d354f" }}>
        <div className="d-flex">
          <div>
            <h1 className="h1">Transaction</h1>
          </div>
        </div>

        <div
          className="d-flex  product-search-combine"
          style={{ gap: "20px", width: "80%", marginTop: "5px" }}
        >
          <Input
          value={values.orderId||null}
            placeholder="Search by order id"
            style={{ width: "100%" }}
            className="select-pending product-search"
            onChange={(e) => setValues({ ...values, orderId: e.target.value })}
          />

          <Select
          value={values.paymentType||null}
            placeholder="Search by Payment"
            className="select-pending product-search-1"
            onChange={(value) => setValues({ ...values, paymentType: value })}
            options={[
              { value: "upi", label: "Pay via UPI" },
              { value: "card", label: "Debit / Credit Card" },
              { value: "cash", label: "Cash on delivery" },
            ]}
          />

          <DatePicker
            style={{ width: "100%", padding: "5px" }}
            onChange={handleDateChange}
            className="product-search"
            placeholder="Select delivery date"
            value={filterDate ? dayjs(filterDate) : null} 
          />

          <div>
            <button
              style={{
                border: "1px solid #0d354f",
                backgroundColor: "#0d354f",
                color: "white",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={filterValues}
            >
              <IoSearch/>
            </button>
          </div>
          <div>
            <button
              style={{
                border: "1px solid #0d354f",
                backgroundColor: "#0d354f",
                color: "white",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={handleReset}
            >
              <RiResetLeftFill />

            </button>
          </div>
        </div>
        <div className="pt-3">
          <p>UPI Transfer: &#8377; {totals.upi.toFixed()}</p>
          <p>Card Transfer: &#8377; {totals.card.toFixed()}</p>
          <p>COD: &#8377; {totals.cash.toFixed()}</p>
          <p>Total Orders: {data.length}</p>
        </div>
        <Paper
          className="table-width"
          sx={{
            width: "96.5%",
            overflow: "hidden",
            border: "1px solid lightgrey",
            marginTop: "30px",
          }}
        >
          <TableContainer component={Paper} className="today-order-table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">
                    <span className="head">Order date</span>
                  </TableCell>
                  <TableCell className="tableHead">
                    <span className="head">Delivery date</span>
                  </TableCell>
                  <TableCell className="tableHead">
                    <span className="head">Order id</span>
                  </TableCell>
                  <TableCell className="tableHead">
                    <span className="head">Product id</span>
                  </TableCell>
                  <TableCell className="tableHead">
                    <span className="head">Customer name</span>
                  </TableCell>
                  <TableCell className="tableHead">
                    <span className="head">Product</span>
                  </TableCell>
                  <TableCell className="tableHead">
                    <span className="head">Mode</span>
                  </TableCell>
                  <TableCell className="tableHead">
                    <span className="head">Price</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((row, i) => (
                    <TableRow
                      hover
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="tablebody"
                      >
                        <p>{moment(row.createdAt).format("Do MMMM YYYY")}</p>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="tablebody"
                      >
                        <p>{moment(row.deliveryDate).format("Do MMMM YYYY")}</p>
                      </TableCell>
                      <TableCell className="tablebody">
                        <p>{row._id.slice(-5)}</p>
                      </TableCell>
                      <TableCell className="tablebody">
                        <p>
                          {row.lineItems?.[0]?.product?._id.slice(-5) || ""}
                        </p>
                      </TableCell>
                      <TableCell className="tablebody">
                        <p>{row.user?.name || ""}</p>
                      </TableCell>
                      <TableCell className="tablebody">
                        <div style={{ display: "flex", gap: "25px" }}>
                          <div>
                            {row.lineItems?.[0]?.product?.image && (
                              <img
                                src={`${API_URL}/images/${row.lineItems[0].product.image}`}
                                style={{ width: "50px", height: "50px" }}
                                alt="meat"
                              />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              flexDirection: "column",
                            }}
                          >
                            <p>{row.lineItems?.[0]?.product?.name || ""}</p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="tablebody">
                        <p>{row.paymentMethod}</p>
                      </TableCell>
                      <TableCell className="tablebody">
                        <p>&#8377; {(row.paymentAmount ?? 0).toFixed()}</p>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          <Pagination
            current={page}
            total={data.length}
            pageSize={rowsPerPage}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
