import React, { useEffect, useState } from "react";
import BrandExample from "./nav";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home";
import Product from "./products.jsx";
import Order from "./orders";
import Banner from "./banners.jsx";
import Business from "./business-with-us.jsx";
import Transaction from "./transaction";
import Port from "./reports.jsx";
import Addnew from "./addnew";
import Detail from "./detail.jsx";
import Login from "./login";
import SignUp from "./Signup";
import ForgetPass from "./Forgetpass";
import Reset from "./Resetpass.jsx";
import EditProduct from "./EditProduct.jsx";
import Management from "./Management.jsx";
import Requirment from "./Api/Requirment.jsx";
import Franchies from "./Franchies.jsx";
import Notification from "./Notification.jsx";
import ViewallOrder from "./ViewallOrder.jsx";
import Customer from "./Customer.jsx";

const Routing = () => {
  const [isUser, setisUser] = useState(false);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("dashtoken");
      setisUser(!!token);
    };

    checkToken();

    const handleStorageChange = () => {
      checkToken();
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  return (
    <>
      <Router>
        {!isUser ? (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forget-password" element={<ForgetPass />} />
            <Route path="/reset-password/:id" element={<Reset />} />
          </Routes>
        ) : (
          <div className="d-flex overall">
            <div style={{ position: "fixed", overflowY: "scroll" }}>
              <BrandExample />
            </div>

            <div
              className="navbar-overall"
              style={{ width: "85%", overflowX: "hidden" }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products" element={<Product />} />
                <Route path="/orders" element={<Order />} />
                <Route path="/banners" element={<Banner />} />
                <Route path="/business-with-us" element={<Business />} />
                <Route path="/transaction" element={<Transaction />} />
                <Route path="/reports" element={<Port />} />
                <Route path="/addnew" element={<Addnew />} />
                <Route path="/edit-product" element={<Addnew />} />
                <Route path="/detail/:id" element={<Detail />} />
                <Route path="/edit-product/:id" element={<EditProduct />} />
                <Route path="/management" element={<Management />} />
                <Route path="/requirement" element={<Requirment />} />
                <Route path="/franchies" element={<Franchies />} />
                <Route path="/customer" element={<Customer />} />
                <Route path="/view-all-order/:id" element={<ViewallOrder />} />
              </Routes>
            </div>
          </div>
        )}
      </Router>
    </>
  );
};

export default Routing;
