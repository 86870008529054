import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

const Invoice = ({ invoiceData }) => {
  const handleDownload = () => {
    const input = document.getElementById("invoice");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      // Increased scale for better quality
      const imgData = canvas.toDataURL("image/png");

      // Create a new jsPDF instance with A4 dimensions
      const pdf = new jsPDF("p", "mm", "a4");

      // Calculate the width and height of the canvas
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add the image to the PDF
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add additional pages if necessary
      while (heightLeft >= 0) {
        position -= pageHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${invoiceData ? invoiceData.user.name : "invoice"}.pdf`);
    });
  };

  const lineItems = invoiceData?.lineItems || [];
  const rows = lineItems.map((item, index) => ({
    serialNo: index + 1,
    name: item.product.name,
    quantity: item.quantity,
    rate: item.discountedPrice || item.product.price,
    amount: item.quantity * item.product.price,
    minWeightInGrams:item.product.minWeightInGrams,

  }));


  const subtotal = lineItems.reduce((acc, item) => {
    const rate = item.discountedPrice || item.product.price;
    return acc + (item.quantity / item.product.minWeightInGrams) * rate;
  }, 0);
  const deliveryCharge = subtotal >= 100 ? 0 : 25;
  const total = (invoiceData?.paymentAmount || 0) + deliveryCharge;

  return (
    <div className="py-3">
      <div className="d-flex justify-content-end">
        <button className="invoicebtn" onClick={handleDownload}>
          Download Invoice
        </button>
      </div>

      <div
        id="invoice"
        style={{
          padding: "20px",
          background: "#fff",
          width: "auto",
          minHeight: "auto",
          border: "1px solid #000",
        }}
      >
        <div className="row">
          <div className="col-lg-6">
            <div>
              <img
                className="img-fluid"
                src="/assests/Vector.png"
                style={{ width: "120px" }}
              />
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-end">
            <div>
              <p>MSME : TN020326092</p>
              <p>Email : info@meatmarket.live</p>
              <p>Contact No : +91 97899 83465 </p>
              <p>Website : www.meatmarket.live</p>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-6">
            <div>
              <p>
                Ordered date:
                {invoiceData && moment(invoiceData.createdAt).format("Do MMMM YYYY")}
              </p>
              <p>Ordered id : {invoiceData && invoiceData._id&&invoiceData._id.slice(-5)}</p>
              <p>Payment : {invoiceData && invoiceData.paymentMethod}</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <p>Customer name : {invoiceData && invoiceData.user.name}</p>
              <p>Customer id : {invoiceData && invoiceData.user._id}</p>
              <p>Customer phone : {invoiceData && invoiceData.user.phone}</p>
              <p>Customer email : {invoiceData && invoiceData.user.email}</p>
              <p>Delivery address : {invoiceData && invoiceData.address}</p>
            </div>
          </div>
        </div>
        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>S.no</TableCell>
                  <TableCell>Item name</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.serialNo}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>
  ₹{" "}
  {row.minWeightInGrams
    ? ((row.quantity / row.minWeightInGrams) * row.rate).toFixed(2)
    : "N/A"}
</TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Subtotal</TableCell>
                  <TableCell>₹ {subtotal.toFixed()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Delivery Charge</TableCell>
                  <TableCell>
                    {deliveryCharge === 0
                      ? "Free"
                      : `₹ ${deliveryCharge.toFixed()}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>₹ {total.toFixed()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
