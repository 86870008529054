import React, { useEffect, useState } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import { FaLessThan } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { getOrder } from "./Api/Apicalls";
import { ApiEndPoints } from "./Api/Apiendpoints";
import moment from "moment";
import Invoice from "./Invioce";

const Detail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  console.log(data,"data");
  

  // Getsingledata
  const handleGetProduct = async () => {
    try {
      const res = await getOrder(ApiEndPoints("getSingleorder"), id);
      setData(res.order);
    } catch (err) {
      console.error(err);
    }
  };

  const lineItems = data?.lineItems || [];

  useEffect(() => {
    handleGetProduct();
  }, []);

  return (
    <div>
      <div className="order-combine">
        <div>
          <h3 className="h1">
            <Link to="/orders">
              <FaLessThan style={{ color: "#0d354f" }} />
            </Link>
            &nbsp;Order details
          </h3>
        </div>

        <Row className="orderdetail">
          <Col xs={6}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "30px" }}
            >
              <div>
                <h6 className="detail-title">Delivery detail</h6>
                <p className="detail-txt">
                  Ordered date :
                  <span className="detail-txt-s">
                    {" "}
                    {data ? moment(data.createdAt).format("Do MMMM YYYY") : ""}
                  </span>
                </p>
                <p className="detail-txt">
                  Ordered id :
                  <span className="detail-txt-s">
                    {data&&data._id ? data._id.slice(-5) : ""}
                  </span>{" "}
                </p>
                <p className="detail-txt">
                  Delivery slot :
                  <span className="detail-txt-s">
                    {data ? data.deliverySlot : ""}
                  </span>
                </p>
                <p className="detail-txt">
                  Delivery Date :
                  <span className="detail-txt-s">
                    {data ? moment(data.deliveryDate).format("DD-MM-YYYY") : ""}
                  </span>
                </p>
                <p className="detail-txt">
                  Delivery instruction :{" "}
                  <span className="detail-txt-s">{data ? data.type : ""}</span>
                </p>
              </div>

              <div>
                <h6 className="detail-title">Product details</h6>
                {/* 
                <p className="detail-txt">
                  Product id : <span className="detail-txt-s">01</span>
                </p> */}
                {lineItems.length > 0 ? (
                  lineItems.map((item, index) => (
                    <div key={index}>
                      <p className="detail-txt">
                        Product name:
                        <span className="detail-txt-s">
                          {" "}
                          {item.product.name}
                        </span>
                      </p>
                      {/* <p className="detail-txt">
                        Amount:
                        <span className="detail-txt-s">
                          {" "}
                          &#8377; {item.product.price.toFixed()}
                        </span>
                      </p> */}
                      <p className="detail-txt">
                        Weight:
                        <span className="detail-txt-s"> {item.quantity} g</span>
                      </p>
                      {/* <p className="detail-txt">Price: <span className="detail-txt-s">₹ {item.price}/<span style={{textDecoration:"line-through"}}>{item.product.price}</span></span></p> */}
                    </div>
                  ))
                 
                ) : (
                  <p className="detail-txt">No products available</p>
                )}
                   <p className="detail-txt">
                        Total:
                        <span className="detail-txt-s">₹ {data&&data.paymentAmount?data.paymentAmount.toFixed():""}</span>
                      </p>
              </div>

              <div>
                <h6 className="detail-title">Payment details</h6>

                <p className="detail-txt">
                  Payment mode :
                  <span className="detail-txt-s">
                    {data ? data.paymentMethod : ""}
                  </span>
                </p>
                <p className="detail-txt">
                  Payment status :
                  <span className="detail-txt-s">
                    {data ? data.paymentStatus : ""}
                  </span>
                </p>
                {/* <p className="detail-txt">Delivery Charges :<span className="detail-txt-s">&#8377; 50</span></p>
                <p className="detail-txt">Coupon code :<span className="detail-txt-s">&#8377; 50 </span></p> */}
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div>
              <h6 className="detail-title">Customer details</h6>

              <p className="detail-txt">
                Customer name :
                <span className="detail-txt-s">
                  {data && data.user && data.user.name
                    ? `${data.user.name}`
                    : "Name unavailable"}
                </span>{" "}
              </p>

              {/* <p className="detail-txt">Contact no :<span className="detail-txt-s">{data && data.lineItems && data.lineItems.length > 0 
      ? `${data.user.phone}` 
      : null}</span></p> */}
              <p className="detail-txt">
                Email id :
                <span className="detail-txt-s">
                  {data && data.user && data.user.email
                    ? `${data.user.email}`
                    : "Email unavailable"}
                </span>
              </p>
              <p className="detail-txt">
                Phone :
                        <span className="detail-txt-s">
                  {data && data.user && data.user.phone
                    ? `${data.user.phone}`
                    : "Phone unavailable"}
                </span>
              </p>
              <p className="detail-txt">
                Address :
                <span className="detail-txt-s">{data ? data.address : ""}</span>
              </p>
              {/* 
              <p className="detail-txt">Bill</p> */}
            </div>
          </Col>
        </Row>
      </div>
      <div className="mx-3 px-4">
        <Invoice invoiceData={data} />
      </div>
    </div>
  );
};

export default Detail;
