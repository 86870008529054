import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";
import { FaPersonChalkboard } from "react-icons/fa6";
import { IoMdHome } from "react-icons/io";
import { HiShoppingCart } from "react-icons/hi";
import { FaBoxOpen } from "react-icons/fa6";
import { RiAdvertisementFill } from "react-icons/ri";
import { MdBusinessCenter } from "react-icons/md";
import { AiOutlineTransaction } from "react-icons/ai";
import { MdReport } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineLogout } from "react-icons/md";
import { MdManageAccounts } from "react-icons/md";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { IoNotificationsCircle } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Avatar, Badge, Space } from "antd";
import { DiRequirejs } from "react-icons/di";


function BrandExample() {
  const location = useLocation();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  const handleLogout = () => {
    navigate("/");
    localStorage.removeItem("dashtoken");
    window.location.reload();
  };

  useEffect(() => {
    const socket = io("https://api.meatmarket.live/");

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("newOrder", (notification) => {
      console.log("New notification:", notification);
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        notification,
      ]);
    });

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      {/* NAVBAR */}
      <Navbar className="bg-body-tertiary side-nav">
        <div className="bar">
          <div>
            <img className="logo" src="/assests/logo-img.png" alt="logo" />
          </div>
          <div className="b-bar-contain">
            <Link to="/" activeStyle style={{ textDecoration: "none" }}>
              <div
                className="b-bar-content"
                id={location.pathname === "/" ? "active" : ""}
              >
                <div className="b-bar-font">
                  <IoMdHome />
                </div>

                <div className="b-bar-font-content">Home</div>
              </div>
            </Link>

            <Link to="/products" activeStyle style={{ textDecoration: "none" }}>
              <div
                className="b-bar-content"
                id={location.pathname === "/products" ? "active" : ""}
              >
                <div className="b-bar-font">
                  <HiShoppingCart />
                </div>

                <div className="b-bar-font-content">Products</div>
              </div>
            </Link>

            <Link to="/orders" activeStyle style={{ textDecoration: "none" }}>
              <div
                className="b-bar-content"
                id={location.pathname === "/orders" ? "active" : ""}
                onClick={()=>setNotifications([])}
              >
                <div className="b-bar-font">
                  <FaBoxOpen />
                </div>
                <Badge count={notifications.length}><div className="" style={{color:`${location.pathname==="/orders"?"#000":"#fff"}`}}>Orders</div></Badge>
              </div>
            </Link>
            <Link to="/banners" activeStyle style={{ textDecoration: "none" }}>
              <div
                className="b-bar-content"
                id={location.pathname === "/banners" ? "active" : ""}
              >
                <div className="b-bar-font">
                  <RiAdvertisementFill />
                </div>

                <div className="b-bar-font-content">Banners</div>
              </div>
            </Link>
            <Link
              to="/business-with-us"
              activeStyle
              style={{ textDecoration: "none" }}
            >
              <div
                className="b-bar-content"
                id={location.pathname === "/business-with-us" ? "active" : ""}
              >
                <div className="b-bar-font">
                  <MdBusinessCenter />
                </div>

                <div className="b-bar-font-content">Business with us</div>
              </div>
            </Link>
            <Link
              to="/transaction"
              activeStyle
              style={{ textDecoration: "none" }}
            >
              <div
                className="b-bar-content"
                id={location.pathname === "/transaction" ? "active" : ""}
              >
                <div className="b-bar-font">
                  <AiOutlineTransaction />
                </div>

                <div className="b-bar-font-content">Transaction</div>
              </div>
            </Link>
            <Link
              to="/franchies"
              activeStyle
              style={{ textDecoration: "none" }}
            >
              <div
                className="b-bar-content"
                id={location.pathname === "/franchies" ? "active" : ""}
              >
                <div className="b-bar-font">
                  <MdManageAccounts />
                </div>

                <div className="b-bar-font-content">Franchies</div>
              </div>
            </Link>

            {/* <Link
              to="/management"
              activeStyle
              style={{ textDecoration: "none" }}
            >
              <div
                className="b-bar-content"
                id={location.pathname === "/management" ? "active" : ""}
              >
                <div className="b-bar-font">
                  <MdManageAccounts />
                </div>

                <div className="b-bar-font-content">Management</div>
              </div>
            </Link> */}
            <Link
              to="/requirement"
              activeStyle
              style={{ textDecoration: "none" }}
            >
              <div
                className="b-bar-content"
                id={location.pathname === "/requirement" ? "active" : ""}
              >
                <div className="b-bar-font">
                <DiRequirejs />
                </div>

                <div className="b-bar-font-content">Requirement</div>
              </div>
            </Link>
            <Link
              to="/customer"
              activeStyle
              style={{ textDecoration: "none" }}
            >
              <div
                className="b-bar-content"
                id={location.pathname === "/customer" ? "active" : ""}
              >
                <div className="b-bar-font">
                <FaPersonChalkboard />
                </div>

                <div className="b-bar-font-content">Customer</div>
              </div>
            </Link>
            <Link to="/reports" activeStyle style={{ textDecoration: "none" }}>
              <div
                className="b-bar-content"
                id={location.pathname === "/reports" ? "active" : ""}
              >
                <div className="b-bar-font">
                  <MdReport />
                </div>

                <div className="b-bar-font-content">Reports</div>
              </div>
            </Link>
            <a to="/reports" activeStyle style={{ textDecoration: "none" }}>
              <div className="b-bar-content">
                <div className="b-bar-font">
                  <MdOutlineLogout  onClick={handleLogout}/>
                </div>
                <div
                  className="b-bar-font-content"
                  style={{ cursor: "pointer" }}
                  onClick={handleLogout}
                >
                  Logout
                </div>
              </div>
            </a>
          </div>
        </div>
      </Navbar>
    </>
  );
}

export default BrandExample;
