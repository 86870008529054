import React, { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { message } from "antd";
import axios from "axios";
import { API_URL } from "./Api/Config";
import * as XLSX from "xlsx";

const Customer = () => {
  const [data, setData] = useState([]);
  const [selectRows, setSelectRows] = useState([]);

  const getCustomers = async () => {
    const token = localStorage.getItem("dashtoken");
    try {
      const res = await axios.get(`${API_URL}/api/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const processedData = res.data.users.map((user) => ({
        id: user._id?.slice(-5),
        name: user.name || "-",
        phone: user.phone || "-",
        email: user.email || "-",
        state: user.address?.state || "-",
        pincode: user.address?.pincode || "-",
      }));
      setData(processedData.reverse());
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "state", headerName: "State", width: 150 },
    { field: "pincode", headerName: "Pincode", width: 150 },
  ];

  const paginationModel = { page: 0, pageSize: 10 };

  const handleRowSelection = (selectionModel) => {
    setSelectRows(selectionModel);
  };

  const handleDownloadExcel = () => {
    const filteredData = data.filter((row) => selectRows.includes(row.id));
    console.log(filteredData, "filteredData");

    // Prepare data for Excel
    const excelData = filteredData.map((row) => ({
      ID: row.id,
      Name: row.name || "-",
      Phone: row.phone || "-",
      Email: row.email || "-",
      State: row.state || "-",
      Pincode: row.pincode || "-",
    }));

    // Create a new workbook and add the data
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Orders.xlsx");
  };

  return (
    <>
      <section className="mx-3">
        <div className="order-combine">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1 className="h1">Customer Data</h1>
            </div>

            <div>
              <button
                style={{
                  border: "1px solid #0d354f",
                  backgroundColor: "#0d354f",
                  color: "white",
                  borderRadius: "5px",
                  padding: "5px 10px",
                }}
                className=""
                onClick={handleDownloadExcel}
              >
                <FiDownload />
              </button>
            </div>
          </div>
          <div className="py-3">
            <Paper sx={{ width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sx={{ border: 0 }}
                disableColumnMenu
                onRowSelectionModelChange={handleRowSelection}
              />
            </Paper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Customer;
